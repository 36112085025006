import {Tabs} from 'antd';
import React from 'react';
import JobLogsTabContent from './JobLogsTabContent';
import './styles/LogContainer.css';

class LogContainer extends React.Component {
  onEdit(target_key, action) {
    if (action === 'remove') {
      this.props.onRemoveJob(target_key);
    }
  }

  render() {
    const { jobs } = this.props;

    if (jobs) {
      return (
        <Tabs
          defaultActiveKey='1'
          size={'small'}
          animated
          className={`log-tabs${jobs.length ? '' : ' hidden'}`}
          type={'editable-card'}
          onEdit={this.onEdit.bind(this)}
          hideAdd
        >
          {jobs.map((job, i) => {
            job.tab_title = job.tab_title || `#${i + 1}`;

            return <Tabs.TabPane
              tab={job.tab_title}
              key={job.id}
              closable
            >
              <JobLogsTabContent job={job}/>
            </Tabs.TabPane>;
          })}
        </Tabs>
      );
    }

    return null;
  }
}

export default LogContainer;
