import React from 'react';
import { Modal, notification, Select } from 'antd';
import ApiClient from '../services/ApiClient';
import config from '../config';
const JOB_STATUS_FINISHED = config.get('job_statuses').finished;
const JOB_STATUS_FAILED = config.get('job_statuses').failed;
const JOB_STATUS_NOT_FOUND = config.get('job_statuses').not_found;

const TIMEOUT_SECONDS = 5;

class BuildMailingCampaignReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailing_campaigns: [],
      mailing_campaign: '',
      job_running: false
    };
  }

  async componentDidMount() {
    await this.refreshMailingCampaigns()
  }


  async refreshMailingCampaigns() {
    const mailing_campaigns = await ApiClient.fetchMailingCampaignsList();

    if (mailing_campaigns) {
      this.setState(() => ({mailing_campaigns}));
    }
  }

  onOk = async () => {
    this.props.outer_setState(() => ({ modal_visible: false }));

    if (this.state.mailing_campaign) {
      const request_body = {
        data: {
          type: 'build_emailing_report',
          campaign_name: this.state.mailing_campaign
        }
      };

      const job = await ApiClient.triggerJob(request_body);

      if (job) {
        const job_refresh_interval = setInterval(async () => {
          const fresh_job = await ApiClient.getJobById(job.id);

          if (fresh_job && fresh_job.status === JOB_STATUS_FINISHED) {
            notification.success({
              message: 'Emailing Report has been built!',
              description: 'It can be downloaded within the "Lists" page'
            });

            clearInterval(job_refresh_interval);
            this.setState(() => ({job_running: false}));
          }

          if ([JOB_STATUS_FAILED, JOB_STATUS_NOT_FOUND].includes(fresh_job.status)) {
            notification.warn({
              message: 'Something went wrong!',
              description: 'Please, check the "Lists" page for the emailing report, if it\'s not there try to trigger building again.'
            });

            clearInterval(job_refresh_interval);
            this.setState(() => ({job_running: false}));
          }
        }, TIMEOUT_SECONDS * 1000);
        this.setState(() => ({job_running: true}));
      }
    }
  };

  render() {
    return <Modal
      title='Report settings'
      visible={!!this.props.outer_state.modal_visible}
      onOk={this.onOk}
      onCancel={() => {
        this.props.outer_setState(() => ({ modal_visible: false }));
      }}
    >
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder='Select Mailing Campaign'
        allowClear
        disabled={this.state.job_running}
        onChange={(value) => {
          this.setState(() => ({ mailing_campaign: value }));
        }}
      >{this.state.mailing_campaigns.map((campaign_name, i) =>
          <Select.Option key={i} value={campaign_name}>{campaign_name}</Select.Option>)}
      </Select>
    </Modal>;
  }
}

export default BuildMailingCampaignReportModal;
