import React from 'react';
import { Context } from '../context';
import { Layout } from 'antd';

import Header from './Header';
import Page from './Page';


class AdminPage extends React.Component {
  render() {

    return (
      <Context.Consumer>{ (context) =>
        <Layout>
          <Header context={context} />
          <Page context={context} />
        </Layout>
      }</Context.Consumer>
    );
  }
}

export default AdminPage;
