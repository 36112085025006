import React from 'react';
import { Layout, Row, Col, Button, Avatar, Modal, Dropdown, Menu } from 'antd';
import { LoginOutlined, UserOutlined } from '@ant-design/icons';
import config from '../config';

const PORTAL_API_URL = config.get('portal_api_url');
const REPORTS_URL = config.get('reports_url');
const LOCAL_STORAGE_KEY = config.get('local_storage_key');


const logOut = () => {
  Modal.confirm({
    title: 'Are you sure you want to log out?',
    onOk: () => {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      window.location.href = `${REPORTS_URL}/logout?redirect=${window.location.href}`;
    },
  });

};
const logIn = () => {
  window.location.href = `${PORTAL_API_URL}/auth/sso?redirect=${window.location.href}`;
};

const Header = ({ context }) => {
  const state = context.state;

  return (
    <Layout.Header className={'header'} style={{ minHeight: context.header_height }}>
      <Row>
        <Col span={8} style={{ float: 'left', height: '100%' }} onClick={context.logoClick} />
        <Col span={8} style={{ fontWeight: 700, fontSize: 16, textAlign: 'center', lineHeight: `${context.header_height}px` }}>
          <img className={'logo'} src={'/new_logo.png'} alt='Inskin'/>
        </Col>
        <Col span={8}>
          {state.user_set && <Row style={{ float: 'right', height: '100%' }}>
            <Col style={{
              float: 'left',
              height: '100%',
              textAlign: 'right',
              lineHeight: `${context.header_height}px`,
              marginRight: 15,
              display: 'flex',
              flexDirection: 'row',
              WebkitBoxAlign: 'center',
              alignItems: 'center'
            }}
            >
              {state.user
                && <div style={{ lineHeight: '1rem', textAlign: 'left' }}>
                  <div style={{ color: 'white' }}>
                    {state.user.firstName} {state.user.lastName}
                  </div>
                  <div style={{ fontSize: 11, color: '#a8a8a8' }}>
                    {state.user.account.name}
                  </div>
                </div>
              }
              {!state.user
                && <Button
                  icon={<LoginOutlined />}
                  onClick={logIn}
                  style={{
                    backgroundColor: '#4a4a4a'
                  }}
                >{'Log in'}</Button>
              }
            </Col>
            {state.user
            && <Col style={{ float: 'right', height: '100%', lineHeight: `${context.header_height}px` }}>
              {/* <img src={'/xmas.png'} style={{*/}
              {/*  position: 'fixed',*/}
              {/*  height: 70,*/}
              {/*  zIndex: 9999,*/}
              {/*  right: 23,*/}
              {/*  top: 6*/}
              {/* }}/>*/}
              <Dropdown overlay={
                <Menu> <Menu.Item key='logout' onClick={logOut}>Log out</Menu.Item></Menu>
              } trigger={['click']}
              ><Avatar size={'large'} icon={<UserOutlined />} />
              </Dropdown>
            </Col>}
          </Row>
          }
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;

/*
--select * from roles;
--select * from role_permissions;
--select * from user_roles;
--select * from users;



--insert into roles (id,`createdAt`,`updatedAt`) values ('agency-publisher-user', current_timestamp(), current_timestamp());
--insert into role_permissions (`roleId`, `permissionId`, `createdAt`,`updatedAt`) values ('agency-publisher-user', 'reports:agency', current_timestamp(), current_timestamp());
--insert into role_permissions (`roleId`, `permissionId`, `createdAt`,`updatedAt`) values ('agency-publisher-user', 'reports:agency-campaigns', current_timestamp(), current_timestamp());
--insert into role_permissions (`roleId`, `permissionId`, `createdAt`,`updatedAt`) values ('agency-publisher-user', 'schedules:write', current_timestamp(), current_timestamp());
--insert into role_permissions (`roleId`, `permissionId`, `createdAt`,`updatedAt`) values ('agency-publisher-user', 'reports:live-campaigns', current_timestamp(), current_timestamp());
--insert into role_permissions (`roleId`, `permissionId`, `createdAt`,`updatedAt`) values ('agency-publisher-user', 'reports:publisher-campaigns', current_timestamp(), current_timestamp());
--insert into role_permissions (`roleId`, `permissionId`, `createdAt`,`updatedAt`) values ('agency-publisher-user', 'reports:revenue', current_timestamp(), current_timestamp());
--update user_roles set `roleId`='agency-publisher-user' where `userId`=7

--update user_roles set `roleId`='agency-user' where `userId`=7
--delete from role_permissions where `roleId`='agency-publisher-user';
--delete from roles where id='agency-publisher-user';
*/
