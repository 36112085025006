import React from 'react';
import AdminPage from './AdminPage';
import Provider from '../context';

class App extends React.Component {
  render() {
    return <Provider>
      <AdminPage />
    </Provider>;
  }
}

export default App;
